<template>
    <div>
        <div class="seperator"></div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    .seperator{
    position: relative;
    width: 100%;
    height: 0px;
    left: 0px;
    margin-top:1px;
    /* top: 300px; */
    border: 1px solid rgba(0, 0, 0, 0.05);
}
</style>