<template>
    <div>
            <v-card elevation="0" class="featured_properties_section" height="261px" @click="viewDetail(card)">
                  <div :style="{ backgroundImage: 'url(' + card.property_cover_image + ')' }" class="property_img">
                        <div class="property_img_top_btn">
                            <v-chip class="ma-2 status" label  >{{card.property_status}}</v-chip>
                            <!-- <v-chip class="ma-2 hotdeal" label color="red">Hot Deal</v-chip> -->
                            <v-spacer/>
                            
                        </div>
                    </div>
                        <div>
                            <span class="title">{{card.property_price| currency}}</span>
                        <v-card-title >
                            <span class="subtitle">{{card.property_name}}</span>
                             <v-spacer></v-spacer>

                        </v-card-title>
                          <v-card-subtitle style="padding:5px">
                          <span class="subtitle">{{card.property_city}}, {{card.property_state}}</span>
                        </v-card-subtitle>
                        <!-- <v-card-subtitle style="padding:5px">
                          <span class="feature_subtitle"> <em class="text-bold">2</em>bd.<em>3</em>baths. <em>650</em>sm </span>
                        </v-card-subtitle> -->
                         <v-card-subtitle style="padding:5px;" class="amenity_container pro_subtitle">
                                        <template  class="amenity_template">
                                            {{card.property_bedrooms}}
                                            <div class="bbs_div" :style="{ backgroundImage: 'url('+require('@/assets/images/beds.png')+')' }"></div>
                                        </template>
                                
                                           <template class="amenity_template">
                                      {{card.property_bathrooms}}
                                   <div class="bbs_div" :style="{ backgroundImage: 'url('+require('@/assets/images/baths.png')+')' }"></div>
                                           </template>
                                           <template class="amenity_template">
                                    {{card.property_size}}
                                     <div class="bbs_div" :style="{ backgroundImage: 'url('+require('@/assets/images/size.png')+')' }"></div>
                                           </template>
                            </v-card-subtitle>
                        <!-- <v-card-title style=""> -->
                            

                        <!-- </v-card-title> -->
                         <!-- <v-card-title style="padding:5px">
                            <span class="subtitle">Ikeja, Lagos</span>

                        </v-card-title> -->
                        
                            
                            
                        </div>
                    <!-- </v-card-actions> -->
                
            </v-card>
        <seperator></seperator>
    </div>
</template>
<script>
import {propertyMixin} from '@/mixins/Property'
export default {
    props:['card'],
    mixins:[propertyMixin]
}
</script>
<style  scoped>
    .property_img{
    background-size: cover;
    width:100%;
    height: 173.06px;
    background-position:center; 
    background-repeat: no-repeat;
    border-radius: 5px;
}
.property_img_top_btn{
    /* margin:5px 5px; */
    display: flex;
    z-index:10;
}
.property_img_top_btn .ma-2{
    color:white !important;
    height: 25px !important;
}
.property_img_top_btn .status{
    color:white !important;
    height: 25px !important;
    background-color: transparent;
    border:1px solid #fff;
}
.subtitle{
    color: rgba(0, 0, 0, 0.47);
    font-size: 11px;

}
.feature_subtitle{
    color: rgba(0, 0, 0, 0.47);
    font-size: 11px;
}
.feature_subtitle .text-bold{
    font-weight: bold !important;
}
     .amenity_container{
        display: flex;
        align-items: center;
        align-content: space-between;
        /* justify-content: center; */
    }
    .pro_subtitle{
        padding-top:10px;
        font-family: Circular Std;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.47);
    }
    .amenity_template{
        margin:0 12px;
    }
    .bbs_div{
         background-size: cover;
         margin-right:10px;
         margin-left: 5px;
        width:10px;
        height: 10px;
        background-position:center; 
        background-repeat: no-repeat;
        /* border-radius: 10px; */
    }
</style>
