<template>
    <div>
        <v-container>
            <div class="text-center" style="display: flex;flex-direction: column">
                <v-icon size="80" style="opacity:0.3" color="#9b9b9b">mdi-eye-off-outline</v-icon>
                <div>
                    <!--                            <v-btn color="secondary"><span style="color: #fff">Search Again</span></v-btn>-->
                </div>

            </div>

        </v-container>
    </div>
</template>

<script>
    export default {
        name: "EmptyList",

    }
</script>

<style scoped>

</style>